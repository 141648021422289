import {Auth } from 'aws-amplify';
import { ILogger } from '../../packages/idn-media-framework-abstraction/ILogger';
import { LoggingLevels } from '../../packages/idn-media-framework-abstraction/LoggingLevels';
import { DIContainer } from '../../packages/idn-media-framework-tsyringe/DIContainer';
import { setTokenCookie, setRefreshTokenCookie, storeTokens } from './tokenHelper';
import { setCookie } from './cookieHelper';
import jwt_decode from 'jwt-decode';

export class OAuthFlow {

  async handleSignIn (){
    
    const logger = DIContainer.Resolve<ILogger>('ILogger').Create('OAuthFlow', LoggingLevels.DEBUG);

    var redirect_uri;
    var authorization_code;
    var clientState;
    let queryStringParams = new URLSearchParams(window.location.search);
    let qsRedirectUri = queryStringParams.get('redirect_uri');
    let qsAuthorizationCode = queryStringParams.get('authorization_code');
    let qsClientState = queryStringParams.get('state');
    const qsClientId = queryStringParams.get('client_id');
    /*
      * For a local sign in the redirect_uri/authorization_code will be in the query string params
      */
    if (qsRedirectUri) {
      logger.debug('redirect_uri detected');

      redirect_uri = qsRedirectUri;
      authorization_code = qsAuthorizationCode;
      clientState = qsClientState;
    } else {
      logger.debug('redirect_uri not detected');
      /*
        * For a federated sign in the redirect_uri/authorization_code will be in the local storage
        */
      redirect_uri = localStorage.getItem('client-redirect-uri');
      authorization_code = localStorage.getItem('authorization_code');
      clientState = localStorage.getItem('client-state');
      localStorage.removeItem(`client-redirect-uri`);
      localStorage.removeItem(`authorization_code`);
      localStorage.removeItem(`client-state`);
    }


    if(redirect_uri){
      if(redirect_uri.startsWith('http')){
        if(redirect_uri.endsWith('/')){
          redirect_uri = redirect_uri.slice(0, redirect_uri.length - 1);
        }  
      }
    }
    /*
      * get the current user session
      */
    let authInfo = await Auth.currentSession();

    let idToken = authInfo.getIdToken().getJwtToken();
    let accessToken = authInfo.getAccessToken().getJwtToken();
    let refreshToken = authInfo.getRefreshToken().getToken();

    /*
      * Set the ID and access token cookies for fast SSO
      */
    if (idToken && accessToken && refreshToken) {
      logger.debug('Token exists, storing to cookies');

      setTokenCookie("id_token", idToken);
      setTokenCookie("access_token", accessToken);

      if (process.env.NEXT_PUBLIC_AUTO_LOGIN_TOGGLE === 'on') {
        if (qsClientId) {
          let tokenDecoded:any = jwt_decode(accessToken);
          let tokenExpiry = tokenDecoded['exp'];
          setCookie('client_id', qsClientId, tokenExpiry);
        } else if (localStorage.getItem("client-id")) {
          let tokenDecoded:any = jwt_decode(accessToken);
          let tokenExpiry = tokenDecoded['exp'];
          setCookie('client_id', localStorage.getItem("client-id"), tokenExpiry);
        }
      }

      /*
        * Set the refresh token cookie. Refresh token cannot be parsed for an an expiry so use the access token to get an expiry.
        * Although the refresh token has a different (longer) expiry than the access token, this is for the purpose of fast SSO,
        * so the refresh token cookie will get set again when the id or access token cookie expires
        */
      setRefreshTokenCookie(refreshToken, accessToken);
    }
    else {
      logger.error("Inconsistent application state: Tokens missing from current session");
      return;
    }

    if (authorization_code && redirect_uri) {
      logger.debug('We are in PKCE flow')
      /*
        * PKCE Flow
        */

      //Store tokens in DynamoDB
      const response = await storeTokens(authorization_code, idToken, accessToken, refreshToken)

      if (response.status === 200) {
        logger.debug('Redirecting to our OAuth client')
        // window.location.replace(redirect_uri + '?code=' + authorization_code + ((clientState !== undefined) ? "&state=" + clientState : ""));
        return {
          isOAuthFlow: true,
          redirectUrl: redirect_uri + '?code=' + authorization_code + ((clientState !== undefined) ? "&state=" + clientState : "")
        }
      }
      else {
        logger.error("Could not store tokens. Server response: " + response.data);
      }
    }
    else if (redirect_uri) {
      logger.debug('We are in implicit flow');

      logger.debug('Redirecting to our OAuth client')
      /*
        * Implicit Flow
        */
      // window.location.replace(redirect_uri + '?id_token=' + idToken + ((clientState !== undefined) ? "&state=" + clientState : ""));
      return {
        isOAuthFlow: true,
        redirectUrl: redirect_uri + '?id_token=' + idToken + ((clientState !== undefined) ? "&state=" + clientState : "")
      }
    }
    else {
      logger.debug('We are not part of any OAuth flow. END');
      /*
        * Sign in directly to broker (not from redirect from client as part of oauth2 flow)
        */
      //props.history.push('/dashboard');

      return {
        isOAuthFlow: false
      }
    }
  
  }  
}