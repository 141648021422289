export const userIdenityType = {
  PHONE_NUMBER: 'phone-number',
  EMAIL: 'email'
};

export const getIdentityType = (value: string): string => {
  const numericValidate = /^\d+$/;
  const phoneNumberWithPlusSignValidate = /^\+[0-9]\d+$/;

  if (numericValidate.test(value) || phoneNumberWithPlusSignValidate.test(value)) {
    return userIdenityType.PHONE_NUMBER;
  } else {
    return userIdenityType.EMAIL;
  }
};
