import React from 'react';
import Layout from 'layouts';
import Button from '@/components/Button';
import { Auth } from "aws-amplify";

interface LoggedInProps {
  user: any;
  handleLogout: () => void;
}

export default function LoggedIn(props: LoggedInProps) {
  const {
    user,
    handleLogout
  } = props;
  const handleRedirectDashboard = async () => {
    const getUserInfo = await Auth.currentUserInfo();
    if (getUserInfo === null || Object.keys(getUserInfo).length === 0) {
      handleLogout();
      return;
    }
    const dashboardUrl = process.env.NEXT_PUBLIC_DEPLOYMENT_ENVIRONMENT === 'production' ? 'https://dashboard.idn.media' : process.env.NEXT_PUBLIC_DEPLOYMENT_ENVIRONMENT === 'staging' ? 'https://dashboard.sateklopo.com' : 'http://localhost:3000';
    window.location.href = `${dashboardUrl}/custom-auth?access_token=${user?.signInUserSession?.accessToken?.jwtToken}`;
  }

  return (
    <Layout heroImageType={'small'} isPattern>
      <div className="flex flex-col items-center justify-center text-center max-w-320px mx-auto">
        <img
          alt="idn-media"
          src="/assets/images/logged-in.svg"
        />
        <h1 className="font-black text-dark_willow text-size16 leading-21px mt-6 mb-2">
          Anda berhasil Login
        </h1>
        <p className="text-tarnished_silver md:text-size14 md:leading-22px text-size12 leading-19px mb-6 w-full break-words max-w-[263px]">
          {/* {user ? JSON.stringify(user.attributes) : 'None'} */}
          Yay! kamu sudah berhasil login menggunakan IDN Account 
        </p>
        {user && (
          <Button onClick={handleRedirectDashboard} text="Lanjut ke IDN Dashboard" type="submit" width="auto" />
        )}
        <div className="absolute bottom-[40px] right-[40px] cursor-pointer" onClick={handleLogout}>
          <span className="text-ottoman_red">Logout</span>
          <img
              alt=""
              src="/assets/icons/logout.svg"
              className="inline-block ml-[8px]"
            />
        </div>
      </div>
    </Layout>
  );
}
