import 'reflect-metadata';

import type { NextPage } from 'next'
import { useEffect, useState } from 'react'
import { Auth} from 'aws-amplify';
import { DIContainer } from '../packages/idn-media-framework-tsyringe/DIContainer'
import { IEventBus } from '../packages/idn-media-framework-abstraction/IEventBus'
// import { FormBox } from '../components/FormBox'
// import { FullscreenContainer } from '../components/FullscreenContainer'
import { CompositionRoot } from '../src/CompositionRoot'
// import { SocialLoginBox } from '../components/SocialLoginBox';
import { LoginBoxWithEmailAndPassword, LoginBoxWithEmailAndPasswordValues } from '../components/LoginBoxWithEmailAndPassword';
// import { LoginForm } from '../components/LoginForm';
import { IAuthenticationService } from '../packages/idn-media-framework-abstraction/IAuthenticationService';
// import { Text, useToast } from '@chakra-ui/react';
import { OAuthFlow } from '../components/helpers/OAuthFlow';
import { ILogger } from '../packages/idn-media-framework-abstraction/ILogger';
// import { ChakraProvider } from '@chakra-ui/react'

import Login from '@/components/Auth/Login';
import NewPasswordChallenge from '@/components/Auth/NewPasswordChallenge';
import LoggedIn from '@/components/Auth/LoggedIn';

import { getIdentityType, userIdenityType } from '@/utility/identityType';
import { getPortal } from '@/utility/getPortal';
import { getPlatform, getDevice, getOS } from '@/utility/userAgent';
import { OTPActivityVerifiedUser, OTPSessionID, ResetPasswordSessionID } from '@/constants/index';
import { saveSessionStorage } from '@/utility/saveSessionStorage';
import { useRouter } from 'next/router';

import { I18n } from 'aws-amplify';
import { CustomErrors } from '@/constants/customErrorStrings';
import { customErrorMessages } from '@/constants/customErrorMessages';

import { CognitoUser } from "@aws-amplify/auth";
import { GetPageLink } from '@/utility/getLinkPage';

import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";

import checkForceAuth from '../components/helpers/checkForceAuth';
import LoadingPage from '@/components/LoadingPage';

// form validation regex
const MIN_CHAR_VLD = 8;
const CHAR_VLD = /^(?=.*[a-z])(?=.*[A-Z]).*$/;
const NUM_VLD = /(?=.*[0-9]).*$/;
const SPCL_VLD = /(?=[A-Za-z0-9!@#$%^&*()]+$)^(?=.*[!@#$%^&*()]).*$/;

const handleIdPLogin = (identity_provider: any) => {
  const logger = DIContainer.Resolve<ILogger>('ILogger');
  // Store redirect_uri/authorization_code in local storage to be used to later
  let queryStringParams = new URLSearchParams(window.location.search);
  let redirect_uri = queryStringParams.get('redirect_uri');
  let authorization_code = queryStringParams.get('authorization_code');
  let clientState = queryStringParams.get('state');

  if (redirect_uri) {
    logger.debug('Storing redirect_uri to storage:', redirect_uri);
    localStorage.setItem(`client-redirect-uri`, redirect_uri);
  }
  if (authorization_code) {
    logger.debug('Storing authorization_code to storage:', authorization_code);
    localStorage.setItem(`authorization_code`, authorization_code);
  }
  if (clientState) {
    logger.debug('Storing clientState to storage:', clientState);
    localStorage.setItem(`client-state`, clientState);
  }
  
  localStorage.setItem(`social_identity_provider`, identity_provider);

  Auth.federatedSignIn({ provider: identity_provider });
}  
interface IpAddressProps {
  ipAddress: string;
}

let isInitialized = false;
const Home: NextPage = () => {

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [identity, setIdentity] = useState('');
  const [password, setPassword] = useState('');
  const [isButtonSubmitDisabled, setIsButtonSubmitDisabled] = useState(true);
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [validationIdentityErr, setValidationIdentityErr] = useState('');
  const [alertMsg, setAlertMsg] = useState('');
  const [alertMsgSocmed, setAlertMsgSocmed] = useState('');
  const [loadingButton, setLoadingButton] = useState(false);
  const [latestPage, setLatestPage] = useState('');

  const [user, setUser] = useState<null | any>(null); // state for cognitoUser object

  // state for force change password page
  const [newPassword, setNewPassword] = useState('');
  const [retypeNewPassword, setRetypeNewPassword] = useState('');
  const [errors, setErrors] = useState(new Map());
  const [name, setName] = useState('');
  const [isNewPasswordRequiredChallange, setIsNewPasswordRequiredChallange] = useState(false);
  const [loadingButtonNewPassword, setLoadingButtonNewPassword] = useState(false);
  const [typingPassTimeout, setTypingPassTimeout] = useState(0);
  const [typingRetypePassTimeout, setTypingRetypePassTimeout] = useState(0);
  const [isButtonSubmitChangePasswordDisabled, setIsButtonSubmitChangePasswordDisabled] =
    useState(true);
  // set loading state on first page load 
  const [isLoadingPage, setIsLoadingPage] = useState(true);

  const router = useRouter();

  const handleIdpRelogin = async () => {
    setIsLoadingPage(true);

    let identity_provider = localStorage.getItem('social_identity_provider');
    if (identity_provider == CognitoHostedUIIdentityProvider.Google) {
      await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
    } else if (identity_provider == CognitoHostedUIIdentityProvider.Facebook) {
      await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Facebook });
    } else if (identity_provider == CognitoHostedUIIdentityProvider.Apple) {
      await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Apple });
    } else {
      setIsLoadingPage(false);
    }
  }

  const init = async () => {
    if(isInitialized) {
      setIsLoadingPage(false);
      return
    };

    await CompositionRoot.InitializeApplication();

    const eventBus = DIContainer.Resolve<IEventBus>('IEventBus');
    await eventBus.CreateTopic('LoginForm');

    await checkForceAuth();

    console.log('Checking login')
    const loginFormSubscription = eventBus.Subscribe('LoginForm', async e => {

      if(e.eventName === 'SocialLoginButtonClicked'){
        handleIdPLogin(e.data);
      }else if(e.eventName === 'LoginWithEmailAndPasswordSubmitted'){
        let formData: LoginBoxWithEmailAndPasswordValues = e.data as any;

        handleLoginWithUsernameAndPassword(formData.email.toLowerCase(), formData.password);
      }
    });

    const authenticationServiceSubscription = eventBus.Subscribe('IAuthenticationService', async e => {
      if(e.eventName === 'signIn'){
        setUser(e.data);

        try {
          setIsLoadingPage(true);
          const OAuthFlowSignIn = await new OAuthFlow().handleSignIn();

          // show logout button on sso broker when not part of any OAuth flow, otherwise redirect to client web
          if (OAuthFlowSignIn?.isOAuthFlow === false) {
            // set loading state false and show logout button if user login directly from sso broker 
            setIsLoadingPage(false);
            setIsLoggedIn(true);
          } else if (OAuthFlowSignIn?.isOAuthFlow === true) {
            // show loading when user redirect to client
            setIsLoadingPage(true);
            if (OAuthFlowSignIn.redirectUrl) {
              window.location.replace(OAuthFlowSignIn.redirectUrl)
            }
          }
        } catch (error: any) {
          // show logout button on sso broker when authorization_code invalid
          if (error.response?.data == 'Invalid authorization_code') {
            setIsLoggedIn(true);
            setIsLoadingPage(false);
          }
        }
      } else if (e.eventName === 'signOut') {
        setIsLoggedIn(false);
        setUser(null);
        setIsNewPasswordRequiredChallange(false);
      } else {
        setIsLoadingPage(false);
      }
    })

    const authenticationService = DIContainer.Resolve<IAuthenticationService>('IAuthenticationService');
    await authenticationService.CheckLoggedIn();

    if (router.query.error_description && /already.found.an.entry.for.username/gi.test(
      router.query.error_description.toString(),
    )
    ) {
      handleIdpRelogin();
    }

    // return (async ()=> {
    //   await authenticationService.CleanUp();
    //   await (await loginFormSubscription).Unsubscribe()
    //   await (await authenticationServiceSubscription).Unsubscribe();
    // })

  }

  const handleLoginButtonSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setLoadingButton(true);
    e.preventDefault();
    setAlertMsg('');

    const data = e.target as typeof e.target & {
      password: { value: string };
    };

    handleLoginWithUsernameAndPassword(identity, data.password.value);

    // const eventBus = DIContainer.Resolve<IEventBus>('IEventBus');
    // eventBus.Dispatch('LoginForm', {eventName: 'LoginWithEmailAndPasswordSubmitted', data: { email: identity, password: data.password.value}, message: ''})
  };

  const handleIdpSignIn = async (providerName: any) => {
    const logger = DIContainer.Resolve<ILogger>('ILogger');
    logger.debug('SocialLoginButton clickHandler called')

    const eventBus = DIContainer.Resolve<IEventBus>('IEventBus');
    eventBus.Dispatch('LoginForm', {eventName: 'SocialLoginButtonClicked', 
      data: providerName, message: ''});
  };

  const handleLoginWithUsernameAndPassword = async (identity: string, password: string) => {
    const clientMetaData = {
      portal: getPortal(window.location.hostname),
      platform: getPlatform(),
      user_agent: navigator.userAgent,
      device: getDevice(),
      operating_system: getOS(),
      ip_address: ''
    };

    // call API Sign In
    Auth.signIn(identity, password, clientMetaData)
    .then(async (user) => {
      setUser(user);
      // showing force change password page to change new password if user status is FORCE_CHANGE_PASSWORD
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setIsNewPasswordRequiredChallange(true);
      } else if (getIsAutenticated(user)) {
        // set user to authenticated if cognito session exist
        const OAuthFlowSignIn = await new OAuthFlow().handleSignIn();

        // show logout button on sso broker when not part of any OAuth flow, otherwise redirect to client web
        if (OAuthFlowSignIn?.isOAuthFlow === false) {
          // set loading state false and show logout button if user login directly from sso broker 
          setIsLoadingPage(false);
          setIsLoggedIn(true);
        } else if (OAuthFlowSignIn?.isOAuthFlow === true) {
          // show loading when user redirect to client
          setIsLoadingPage(true);
          if (OAuthFlowSignIn.redirectUrl) {
            window.location.replace(OAuthFlowSignIn.redirectUrl)
          }
        }

        // const eventBus = DIContainer.Resolve<IEventBus>('IEventBus');
        // eventBus.Dispatch('IAuthenticationService', {eventName: 'signIn', data: user, message: ''})
      }

      setLoadingButton(false);
      setPassword('');
    })
    .catch((error) => {
      // show logout button on sso broker when authorization_code invalid
      if (error.response?.data == 'Invalid authorization_code') {
        setIsLoggedIn(true);
      }
      // handle user with status not confirmed
      else if (error.code == 'UserNotConfirmedException') {
        resendConfirmationSignUpCode(identity);
        // handle user with requiring reset their passwords
      } else if (error.code == 'PasswordResetRequiredException') {
        userForceResetPasswordRequired(identity);
      } else {
        let errMsg = CustomErrors.DEFAULT_MSG;

        if (error.message) {
          if (
            error.message == CustomErrors.USER_NOT_EXIST ||
            error.message == CustomErrors.USER_NOT_EXIST_IDNACCOUNT
          ) {
            if (getIdentityType(identity) == userIdenityType.PHONE_NUMBER) {
              errMsg = I18n.get('UserNotFoundExceptionPhoneNumber');
            } else {
              errMsg = I18n.get('UserNotFoundExceptionEmail');
            }
          } else {
            // check error message on defined error message lists to prevent showing a technical error message to user
            if (customErrorMessages.hasOwnProperty(error.message)) {
              errMsg = I18n.get(error.message);
            }
          }
        }

        setLoadingButton(false);
        setAlertMsg(errMsg);
        setAlertMsgSocmed('');
        setPassword('');
      }
    });
  };

  const resendConfirmationSignUpCode = (userIdentity: string) => {
    const clientMetaData = {
      portal: getPortal(window.location.hostname),
      platform: getPlatform(),
      user_agent: navigator.userAgent,
      device: getDevice(),
      operating_system: getOS(),
      ip_address: ''
    };

    Auth.resendSignUp(userIdentity, clientMetaData)
      .then((user) => {
        saveSessionStorage(OTPSessionID, {
          user_identity: userIdentity,
          activity_otp: OTPActivityVerifiedUser
        });

        let pathname = GetPageLink('/verification', router.query);

        router.push(pathname);
      })
      .catch((error) => {
        let errMsg = CustomErrors.DEFAULT_MSG;

        if (error.message) {
          if (error.message == CustomErrors.ATTEMPT_LIMIT_EXCEEDED) {
            errMsg = I18n.get('LimitExceededResendCodeException');
          } else {
            // check error message on defined error message lists to prevent showing a technical error message to user
            if (customErrorMessages.hasOwnProperty(error.message)) {
              errMsg = I18n.get(error.message);
            }
          }
        }

        setLoadingButton(false);
        setPassword('');
        setAlertMsg(errMsg);
        setAlertMsgSocmed('');
      });
  };

  const userForceResetPasswordRequired = (userIdentity: string) => {
    const clientMetaData = {
      portal: getPortal(window.location.hostname),
      platform: getPlatform(),
      user_agent: navigator.userAgent,
      device: getDevice(),
      operating_system: getOS(),
      ip_address: '',
      user_identity: userIdentity
    };

    Auth.forgotPassword(userIdentity, clientMetaData)
      .then((data) => {
        setLoadingButton(false);

        saveSessionStorage(ResetPasswordSessionID, {
          user_identity: userIdentity
        });

        let pathname = GetPageLink('/reset-password', router.query);

        // redirect to reset password page for input verification code and new password
        router.push(pathname);
      })
      .catch((error) => {
        let errMsg = CustomErrors.DEFAULT_MSG;

        if (error.message) {
          // check error message on defined error message lists to prevent showing a technical error message to user
          if (customErrorMessages.hasOwnProperty(error.message)) {
            errMsg = I18n.get(error.message);
          }
        }

        setLoadingButton(false);
        setPassword('');
        setAlertMsg(errMsg);
        setAlertMsgSocmed('');
      });
  };
  
  const getIsAutenticated = (user: CognitoUser): boolean => {
    if (
      !user ||
      !user.getSignInUserSession() ||
      !user.getSignInUserSession()!.isValid() // isValid() also verified the Token Signature
    ) {
      return false
    }
    return true;
  }

  const handleValidateIdentity = (e: React.FormEvent<HTMLInputElement>, delay: number = 0) => {
    let userIdentity = (e.target as HTMLInputElement).value?.toLowerCase().trim();
    setIdentity(userIdentity);
    setValidationIdentityErr('');
    setAlertMsg('');
    setAlertMsgSocmed('');

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(
      window.setTimeout(function () {
        if ((e.target as HTMLInputElement).value == '') {
          setValidationIdentityErr('');
          setIsButtonSubmitDisabled(true);
        } else if (getIdentityType(userIdentity) == userIdenityType.PHONE_NUMBER) {
          if (userIdentity.substring(0, 1) == '0') {
            userIdentity = '+62' + userIdentity.substring(1);
          } else if (userIdentity.substring(0, 2) == '62') {
            userIdentity = '+' + userIdentity;
          }
          setIdentity(userIdentity);
        } else {
          setValidationIdentityErr('');
        }
      }, delay)
    );
  };

  const handleValidatePasswordValue = (e: React.FormEvent<HTMLInputElement>) => {
    setAlertMsg('');
    setAlertMsgSocmed('');
    setPassword((e.target as HTMLInputElement).value);

    if (validationIdentityErr == '' && identity !== '' && password !== '') {
      setIsButtonSubmitDisabled(false);
    } else {
      setIsButtonSubmitDisabled(true);
    }
  };

  const submitChangeTemporaryPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    setLoadingButtonNewPassword(true);
    e.preventDefault();

    const clientMetaData = {
      portal: getPortal(window.location.hostname),
      platform: getPlatform(),
      user_agent: navigator.userAgent,
      device: getDevice(),
      operating_system: getOS(),
      ip_address: ''
    };

    Auth.completeNewPassword(
      user, // the Cognito User Object
      newPassword, // the new password
      // OPTIONAL, the required attributes
      {
        name: name
      },
      clientMetaData
    )
      .then(async (user) => {
        setLoadingButtonNewPassword(false);
        setName('');
        setNewPassword('');
        setRetypeNewPassword('');
        setIsNewPasswordRequiredChallange(false);
        // at this time the user is logged in if no MFA required
        // router.push('/');

        setIsLoggedIn(true);
        setUser(user);

        const OAuthFlowSignIn = await new OAuthFlow().handleSignIn();

        if (OAuthFlowSignIn?.isOAuthFlow === false) {
          // set loading state false and show logout button if user login directly from sso broker
          setIsLoadingPage(false);
          setIsLoggedIn(true);
        } else if (OAuthFlowSignIn?.isOAuthFlow === true) {
          // show loading when user redirect to client
          setIsLoadingPage(true);
          if (OAuthFlowSignIn.redirectUrl) {
            window.location.replace(OAuthFlowSignIn.redirectUrl)
          }
        }

        // const eventBus = DIContainer.Resolve<IEventBus>('IEventBus');
        // eventBus.Dispatch('IAuthenticationService', {eventName: 'signIn', data: user, message: ''})
      })
      .catch((error) => {
        setLoadingButtonNewPassword(false);
        setName('');
        setNewPassword('');
        setRetypeNewPassword('');

        let errMsg = CustomErrors.DEFAULT_MSG;
        
        // show logout button on sso broker when authorization_code invalid
        if (error.response?.data == 'Invalid authorization_code') {
          setIsLoggedIn(true);
        } else if (error.message) {
          // check error message on defined error message lists to prevent showing a technical error message to user
          if (customErrorMessages.hasOwnProperty(error.message)) {
            errMsg = I18n.get(error.message);
          }
        }

        setErrors((map) => new Map(map.set('api', errMsg)));
      });
  };

  const validateName = (e: React.FormEvent<HTMLInputElement>) => {
    setName((e.target as HTMLInputElement).value);
  };

  const validateNewPassword = (e: React.FormEvent<HTMLInputElement>, delay = 0) => {
    setErrors((map) => map.set('api', ''));
    let value = (e.target as HTMLInputElement).value;
    let input = (e.target as HTMLInputElement).name;

    if (input == 'retype-new-password') {
      setRetypeNewPassword(value);
      setErrors((map) => new Map(map.set('retypeNewPassword', '')));
      if (typingRetypePassTimeout) {
        clearTimeout(typingRetypePassTimeout);
      }
      setTypingRetypePassTimeout(
        window.setTimeout(() => {
          if (newPassword != value) {
            setErrors(
              (map) =>
                new Map(
                  map.set('retypeNewPassword', 'Oops, password yang kamu masukkan tidak sama.')
                )
            );
          } else {
            setErrors((map) => new Map(map.set('retypeNewPassword', '')));
          }
        }, delay)
      );
    } else if (input == 'new-password') {
      setNewPassword(value);
      setErrors((map) => new Map(map.set('newPassword', '')));
      if (typingPassTimeout) {
        clearTimeout(typingPassTimeout);
      }
      setTypingPassTimeout(
        window.setTimeout(() => {
          if (value == '') {
            setErrors((map) => new Map(map.set('newPassword', '')));
            setIsButtonSubmitChangePasswordDisabled(true);
          } else if (value.length < MIN_CHAR_VLD) {
            setErrors(
              (map) => new Map(map.set('newPassword', 'Password kamu kurang dari 8 karakter.'))
            );
          } else if (CHAR_VLD.test(value) === false) {
            setErrors(
              (map) =>
                new Map(
                  map.set('newPassword', 'Password harus terdiri dari huruf besar dan huruf kecil.')
                )
            );
          } else if (NUM_VLD.test(value) === false || SPCL_VLD.test(value) === false) {
            setErrors(
              (map) =>
                new Map(
                  map.set('newPassword', 'Password wajib ada 1 simbol !@#$%^&*() dan 1 angka.')
                )
            );
          } else {
            setErrors((map) => new Map(map.set('newPassword', '')));
          }

          if (retypeNewPassword.length != 0 && retypeNewPassword != value) {
            setErrors(
              (map) =>
                new Map(
                  map.set('retypeNewPassword', 'Oops, password yang kamu masukkan tidak sama.')
                )
            );
          } else {
            setErrors((map) => new Map(map.set('retypeNewPassword', '')));
          }
        }, delay)
      );
    }
  };

  useEffect(() => {
    setIsButtonSubmitChangePasswordDisabled(true);

    if (
      errors.get('newPassword') ||
      errors.get('retypeNewPassword') ||
      retypeNewPassword == '' ||
      newPassword == '' ||
      name == '' ||
      retypeNewPassword != newPassword
    ) {
      setIsButtonSubmitChangePasswordDisabled(true);
    } else {
      setIsButtonSubmitChangePasswordDisabled(false);
    }
  }, [errors, newPassword, retypeNewPassword, name]);

  useEffect(() => {
    setIsButtonSubmitDisabled(true);

    if (validationIdentityErr !== '' || identity == '' || password == '') {
      setIsButtonSubmitDisabled(true);
    } else {
      setIsButtonSubmitDisabled(false);
    }

    // if (router.query.latest_page) {
    //   const latestPage = router.query.latest_page;
    //   setLatestPage(latestPage[0]);
    // } else {
    //   setLatestPage(redirectHomepage(window.location.hostname));
    // }
  }, [validationIdentityErr, password, identity]);

  useEffect(() => {
    // checking when error message from facebook exist
    if (router.query.error_description) {
      const errorDesc = router.query.error_description.toString().trim();

      // handle email permission disabled by user
      if (errorDesc == CustomErrors.DISABLE_EMAIL_PERMISSION_FACEBOOK || errorDesc == CustomErrors.DISABLE_EMAIL_PERMISSION_FACEBOOK_PRESIGNUP_TRIGGER || errorDesc == CustomErrors.USER_ALREADY_DELETED || errorDesc == CustomErrors.USER_ALREADY_DELETED_REGISTER_IDNACCOUNT || errorDesc == CustomErrors.USER_ALREADY_DELETED_SIGNIN_IDNACCOUNT) {
        setAlertMsgSocmed(I18n.get(errorDesc));
        // handle when user canceled login process on fb and apple, or hide error for linked user
      } else if(errorDesc == CustomErrors.USER_ALREADY_EXIST_REGISTER_IDNACCOUNT) {
        if (getIdentityType(identity) == userIdenityType.PHONE_NUMBER) {
          setAlertMsgSocmed(I18n.get(CustomErrors.PHONE_NUMBER_ALREADY_EXIST_REGISTER));
        } else {
          setAlertMsgSocmed(I18n.get(CustomErrors.EMAIL_ALREADY_EXIST_REGISTER));
        }
      } else if (/password.reset.required.for.the.user/gi.test(router.query.error_description.toString())) {
        setAlertMsgSocmed(CustomErrors.SOCMED_SIGNIN_RESET_REQUIRED);
      } else if (errorDesc == CustomErrors.ACCESS_DENIED_FACEBOOK || errorDesc == CustomErrors.CANCELLED_AUTHORIZE_APPLE || /already.found.an.entry.for.username/gi.test(router.query.error_description.toString())) {
        return;
      } else {
        setAlertMsgSocmed(CustomErrors.DEFAULT_MSG);
      }
    }
  }, [router.isReady, router.query.error, router.query.error_description]);

  const handleLogout = async () => {
    const service = DIContainer.Resolve<IAuthenticationService>('IAuthenticationService');
    await service.Logout();

    await router.push('/signout');

    setIsLoggedIn(false);
    setUser(null);
    setIsNewPasswordRequiredChallange(false);

    // const eventBus = DIContainer.Resolve<IEventBus>('IEventBus');
    // eventBus.Dispatch('IAuthenticationService', {eventName: 'signOut', data: user, message: ''})
  };

  useEffect(()=> {
    init();    
    isInitialized = true;    
  }, [])

  return (
    // <ChakraProvider>
    //   <FullscreenContainer>
    //     <LoginForm/>
    //     {/* {!isLoggedIn && <LoginForm/>}
    //     {isLoggedIn && <Text>You are logged in</Text>} */}
    //   </FullscreenContainer>
    // </ChakraProvider>
    <>
      {isLoggedIn ? (
        <LoggedIn user={user} handleLogout={handleLogout} />
      ) : isNewPasswordRequiredChallange ? (
        <NewPasswordChallenge
          newPassword={newPassword}
          retypeNewPassword={retypeNewPassword}
          name={name}
          handleSubmit={submitChangeTemporaryPassword}
          handleNewPasswordValidation={validateNewPassword}
          newPasswordValidationError={errors.get('newPassword')}
          retypeNewPasswordValidationError={errors.get('retypeNewPassword')}
          handleNameValidation={validateName}
          nameValidationError={errors.get('name')}
          isButtonSubmitLoading={loadingButtonNewPassword}
          isButtonSubmitDisabled={isButtonSubmitChangePasswordDisabled}
          alertMessage={errors.get('api')}
        />
      ) : isLoadingPage ? (<LoadingPage />) : (
        <Login
          identity={identity}
          password={password}
          handleIdpSignIn={handleIdpSignIn}
          handleLoginSubmit={handleLoginButtonSubmit}
          handleIdentityValidation={handleValidateIdentity}
          identityValidationError={validationIdentityErr}
          handlePasswordValueValidation={handleValidatePasswordValue}
          isButtonSubmitDisabled={isButtonSubmitDisabled}
          isButtonSubmitLoading={loadingButton}
          alertMessage={alertMsg}
          alertMessageSocmed={alertMsgSocmed}
        />
      )}
    </>
  )
}

export default Home
