import React, { useState } from 'react';
import Head from 'next/head';
import Layout from 'layouts';
import FormInput from '@/components/Form/FormInput';
import Button from '@/components/Button';
import Alert from '@/components/Alert';
import ErrorMessage from '@/components/Form/ErrorMessage';
import LoadingOnButton from '@/components/Button/LoadingOnButton';
import { EyeClose, EyeOpen } from 'components/IconSvg';

interface NewPasswordChallengeProps {
  newPassword: string;
  retypeNewPassword: string;
  name: string;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
  handleNewPasswordValidation: (e: React.FormEvent<HTMLInputElement>, delay?: number) => void;
  newPasswordValidationError: string;
  retypeNewPasswordValidationError: string;
  handleNameValidation: (e: React.FormEvent<HTMLInputElement>) => void;
  nameValidationError: string;
  isButtonSubmitLoading: boolean;
  isButtonSubmitDisabled: boolean;
  alertMessage: string;
}

export default function NewPasswordChallenge(props: NewPasswordChallengeProps) {
  const {
    newPassword,
    retypeNewPassword,
    name,
    handleSubmit,
    handleNewPasswordValidation,
    newPasswordValidationError,
    retypeNewPasswordValidationError,
    handleNameValidation,
    nameValidationError,
    isButtonSubmitLoading,
    isButtonSubmitDisabled,
    alertMessage
  } = props;

  const [hidePwd, setHidePwd] = useState(true);
  const [hideRePwd, setHideRePwd] = useState(true);

  return (
    <Layout heroImageType="small">
      <Head>
        <title>IDN Account - Ubah password</title>
      </Head>
      <div className="flex flex-col w-full">
        <h1 className="text-xl font-black">Ubah password</h1>
        <p className="text-sm text-gray_account-default mb-6">
          Buat password baru demi keamanan akunmu.
        </p>
        <form className="mb-6" onSubmit={(e) => handleSubmit(e)}>
          {alertMessage ? <Alert text={alertMessage} /> : null}
          <div className="mb-2">
            <FormInput
              value={newPassword}
              label="Password Baru"
              type={hidePwd ? 'password' : 'text'}
              name="new-password"
              isError={newPasswordValidationError ? true : false}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                handleNewPasswordValidation(e, 2000)
              }
              onBlur={(e: React.FormEvent<HTMLInputElement>) => handleNewPasswordValidation(e)}
            >
              <span className="h-full w-auto leading-snug text-center absolute top-0 right-0 bg-transparent flex items-center justify-center px-3 py-3">
                <div className="cursor-pointer" onClick={() => setHidePwd(!hidePwd)}>
                  {hidePwd ? <EyeClose /> : <EyeOpen />}
                </div>
              </span>
              {newPasswordValidationError ? (
                <ErrorMessage text={newPasswordValidationError} />
              ) : null}
            </FormInput>
          </div>
          <div className="mb-2">
            <FormInput
              label="Ketik Ulang Password Baru"
              value={retypeNewPassword}
              type={hideRePwd ? 'password' : 'text'}
              name="retype-new-password"
              isError={retypeNewPasswordValidationError ? true : false}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                handleNewPasswordValidation(e, 2000)
              }
              onBlur={(e: React.FormEvent<HTMLInputElement>) => handleNewPasswordValidation(e)}
            >
              <span className="h-full w-auto leading-snug text-center absolute top-0 right-0 bg-transparent flex items-center justify-center px-3 py-3">
                <div className="cursor-pointer" onClick={() => setHideRePwd(!hideRePwd)}>
                  {hideRePwd ? <EyeClose /> : <EyeOpen />}
                </div>
              </span>
              {retypeNewPasswordValidationError ? (
                <ErrorMessage text={retypeNewPasswordValidationError} />
              ) : null}
            </FormInput>
          </div>
          <div className="mb-4">
            <FormInput
              value={name}
              label="Nama"
              type="text"
              name="name"
              isError={nameValidationError ? true : false}
              onChange={(e: React.FormEvent<HTMLInputElement>) => handleNameValidation(e)}
            >
              {nameValidationError ? <ErrorMessage text={nameValidationError} /> : null}
            </FormInput>
          </div>
          <Button
            type="submit"
            text={isButtonSubmitLoading ? <LoadingOnButton /> : 'Ubah Password'}
            disabled={
              isButtonSubmitLoading ||
              retypeNewPasswordValidationError !== '' ||
              newPasswordValidationError !== '' ||
              isButtonSubmitDisabled
            }
            mergeClass="mb-6"
          />
        </form>
      </div>
    </Layout>
  );
}
