import React, { useState, useEffect } from 'react';
import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Layout from 'layouts';
import FormInput from '@/components/Form/FormInput';
import Button from '@/components/Button';
import Alert from '@/components/Alert';
import ErrorMessage from '@/components/Form/ErrorMessage';
import LoadingOnButton from '@/components/Button/LoadingOnButton';
import { ArrowRight, EyeClose, EyeOpen } from 'components/IconSvg';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { GetPageLink } from '@/utility/getLinkPage';
import isWebview from 'is-ua-webview';

interface LoginProps {
  identity?: string;
  password: string;
  alertMessage: string;
  alertMessageSocmed: string;
  handleLoginSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
  handleIdpSignIn: (providerName: any) => void;
  handleIdentityValidation: (e: React.FormEvent<HTMLInputElement>, delay?: number) => void;
  identityValidationError: string;
  handlePasswordValueValidation: (e: React.FormEvent<HTMLInputElement>, delay?: number) => void;
  isButtonSubmitDisabled: boolean;
  isButtonSubmitLoading: boolean;
}

export default function Login(props: LoginProps) {
  const {
    identity,
    password,
    alertMessage,
    alertMessageSocmed,
    handleLoginSubmit,
    handleIdpSignIn,
    handleIdentityValidation,
    identityValidationError,
    handlePasswordValueValidation,
    isButtonSubmitDisabled,
    isButtonSubmitLoading
  } = props;

  const [hidePassword, setHidePassword] = useState(true);
  const router = useRouter();
  const [isOpenOnWebview, setIsOpenOnWebview] = useState(false);

  const checIskWebview = () => {
    const userAgent = window.navigator.userAgent || '';
    const detectWebviewResult = isWebview(userAgent);

    setIsOpenOnWebview(detectWebviewResult);
  }

  useEffect(()=> {
    checIskWebview();
  }, [])

  return (
    <Layout heroImageType={'large'}>
      <Head>
        <title>IDN Account - Login</title>
      </Head>
      <div className="mb-8">
        <h2 className="text-dark_willow text-size16 leading-22px sm:text-size18 sm:leading-24px mb-2 font-black">
          Masuk ke IDN Account
        </h2>
        <p className="text-tarnished_silver text-size12 leading-18px sm:text-size14 sm:leading-22px mb-6 max-w-full sm:max-w-320px">
          Cukup satu akun kamu bisa menikmati berbagai konten IDN.
        </p>
        <div className="flex flex-col">
          {/* hide login with socmed if user open login page from webview */}
          {!isOpenOnWebview && (
            <div className="order-2 sm:order-1 flex flex-col">
              <div className="order-2 sm:order-1 block mb-4">
                <a
                  data-testid="sign-in-apple"
                  className="cursor-pointer relative flex items-center justify-center px-39px py-3 sm:py-4 border border-calla_lily rounded-lg mb-2"
                  onClick={() => handleIdpSignIn(CognitoHostedUIIdentityProvider.Apple)}
                >
                  <i className="absolute left-4 w-6 h-6 flex flex-none items-center justify-center mr-2">
                    <Image
                      src="/assets/icons/apple_icon.svg"
                      width="24"
                      height="24"
                      alt="google logo"
                    />
                  </i>
                  <p className="text-size14 leading-17px font-bold">
                    Masuk dengan Apple
                  </p>
                </a>
                <a
                  data-testid="sign-in-facebook"
                  className="cursor-pointer relative flex items-center justify-center px-39px py-3 sm:py-4 border border-calla_lily rounded-lg mb-2"
                  onClick={() => handleIdpSignIn(CognitoHostedUIIdentityProvider.Facebook)}
                >
                  <i className="absolute left-4 w-6 h-6 flex flex-none items-center justify-center mr-2">
                    <Image
                      src="/assets/icons/facebook_icon.svg"
                      width="24"
                      height="24"
                      alt="facebook logo"
                    />
                  </i>
                  <p className="text-size14 leading-17px font-bold">
                    Masuk dengan Facebook
                  </p>
                </a>
                <a
                  data-testid="sign-in-google"
                  className="cursor-pointer relative flex items-center justify-center px-39px py-3 sm:py-4 border border-calla_lily rounded-lg"
                  onClick={() => handleIdpSignIn(CognitoHostedUIIdentityProvider.Google)}
                >
                  <i className="absolute left-4 w-6 h-6 flex flex-none items-center justify-center mr-2">
                    <Image
                      src="/assets/icons/google_icon.svg"
                      width="24"
                      height="24"
                      alt="google logo"
                    />
                  </i>
                  <p className="text-size14 leading-17px font-bold">
                    Masuk dengan Google
                  </p>
                </a>
              </div>
              <div className="order-1 sm:order-2 relative">
                {alertMessageSocmed ? <Alert text={alertMessageSocmed} /> : null}
              </div>
              <div className="order-1 sm:order-2 relative flex items-center justify-center mb-4">
                <span className="relative bg-white text-silver_charm text-size12 leading-16px px-10px text-center z-10">
                  atau
                </span>
                <span className="absolute border border-calla_lily h-px w-full top-1/2 left-0 translate-y-1/2"></span>
              </div>
            </div>
          )}
          <div className="order-1 sm:order-2">
            <form className="mb-6" onSubmit={(e) => handleLoginSubmit(e)}>
              {alertMessage ? <Alert text={alertMessage} /> : null}
              <div className="mb-2">
                <FormInput
                  data-testid="field-email"
                  type="text"
                  name="user_identity"
                  label="Email/Nomor HP"
                  isError={identityValidationError ? true : false}
                  onChange={(e: React.FormEvent<HTMLInputElement>) =>
                    handleIdentityValidation(e, 0)
                  }
                  onBlur={(e: React.FormEvent<HTMLInputElement>) => handleIdentityValidation(e)}
                >
                  {identityValidationError ? <ErrorMessage text={identityValidationError} /> : null}
                </FormInput>
              </div>
              <div className="mb-4">
                <FormInput
                  data-testid="field-password"
                  label="Password"
                  type={hidePassword ? 'password' : 'text'}
                  name="password"
                  value={password}
                  isError={false}
                  onChange={(e: React.FormEvent<HTMLInputElement>) =>
                    handlePasswordValueValidation(e)
                  }
                >
                  <span className="h-full w-auto leading-snug text-center absolute top-0 right-0 bg-transparent flex items-center justify-center px-3 py-3">
                    <div className="cursor-pointer" onClick={() => setHidePassword(!hidePassword)}>
                      {hidePassword ? <EyeClose /> : <EyeOpen />}
                    </div>
                  </span>
                </FormInput>
              </div>
              <Button
                data-testid="button-masuk"
                type="submit"
                text={isButtonSubmitLoading ? <LoadingOnButton /> : 'Masuk'}
                disabled={isButtonSubmitDisabled || isButtonSubmitLoading}
                mergeClass="mb-6"
              />
            </form>
            <p className="text-tarnished_silver text-size12 leading-18px sm:text-size14 sm:leading-17px text-center mb-4">
              Lupa password akunmu?{' '}
              <Link href={GetPageLink('/forgot-password', router.query)}>
                <a className="font-bold text-deep_skyblue cursor-pointer">Atur ulang password</a>
              </Link>
            </p>
          </div>
          <p className="order-3 text-tarnished_silver text-size12 leading-18px sm:text-size14 sm:leading-17px text-center">
            Belum punya akun?{' '}
            <Link href={GetPageLink('/register', router.query)}>
              <a className="font-bold text-deep_skyblue cursor-pointer">Yuk, daftar</a>
            </Link>
          </p>
        </div>
      </div>
      <Link href={process.env.NEXT_PUBLIC_DEPLOYMENT_ENVIRONMENT === 'production' ? 'https://dashboard.idn.media/user-guide' : process.env.NEXT_PUBLIC_DEPLOYMENT_ENVIRONMENT === 'staging' ? 'https://dashboard.sateklopo.com/user-guide' : 'http://localhost:3000/user-guide'}>
        <a target="_blank" className="flex w-full gap-1 px-3 py-2 md:px-4 md:py-3 items-center justify-between border border-calla_lily rounded-lg">
          <div className="flex items-center">
            <div className="w-6 h-6 md:w-9 md:h-9 mr-4">
              <img alt="faq-icon" src="/assets/icons/faq.svg" />
            </div>
            <p className="text-bastille text-size12 leading-19px font-bold">
              Cari tau lebih tentang IDN Dashboard
            </p>
          </div>
          <div className="w-4 h-4">
            <ArrowRight />
          </div>
        </a>
      </Link>
      <div className='mt-8'>
        <h3 className='text-size14 leading-17px font-bold mb-3'>Layanan Pengaduan Konsumen</h3>
        <div>
          <p className='text-size12 leading-17px font-normal mb-1'>IDN (PT Media Putra Nusantara)</p>
          <p className='text-size12 leading-17px font-normal mb-2'>
            Jl.Jend Gatot Subroto Kav.27, 3rd Floor, Kuningan, Jakarta (12950)<br/>
            +62 21 397 13888<br/>
          </p>
          <p className='text-size12 leading-17px font-normal'>
            Direktorat Jenderal Perlindungan Konsumen dan Tertib Niaga<br/>
            Kementerian Perdagangan<br/>
            0853 1111 1010 (WhatsApp)
          </p>
        </div>
      </div>
    </Layout>
  );
}
